export default [

  
  // {
  //   title: 'My Profile',
  //   icon: 'UserIcon',
  //   route: 'user-profile',
  //   Permission: '',
  // },


  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'home',
    Permission: 'home_page_menu',
  },

  {
    title: 'Tool',
    icon: 'CrosshairIcon',
    route: 'dashboard',
    Permission: '',
  },

  {
    title: 'Report',
    icon: 'CheckSquareIcon',
    route: 'report',
    Permission: 'report_page_menu',
  },

  // {
  //   title: 'Clients',
  //   icon: 'UsersIcon',
  //   Permission: 'client_page_menu',
  //   route: 'clients',
  // },

  // {
  //   title: 'Contacts',
  //   icon: 'UserIcon',
  //   Permission: 'contact_page_menu',
  //   route: 'contact-list',
  // },

  // {
  //   title: 'Todo',
  //   route: 'todo-dashboard',
  //   icon: 'CheckSquareIcon',
  //   Permission: 'todo_page_menu',
  // },

  // {
  //   title: 'Files',
  //   route: 'file-list',
  //   icon: 'FolderIcon',
  //   Permission: 'files_page_menu',
  // },

  // {
  //   title: 'Projects',
  //   route: 'projects',
  //   icon: 'GridIcon',
  //   Permission: 'projects_page_menu',
  // },

  // {
  //   title: 'Invoices',
  //   route: 'invoice-list',
  //   icon: 'ClipboardIcon',
  //   Permission: '',
  // },

  // {
  //   title: 'Quotes',
  //   route: 'quote-list',
  //   icon: 'FileIcon',
  //   Permission: '',
  // },

  // {
  //   title: 'Scopes',
  //   icon: 'InboxIcon',
  //   Permission: '',
  //   children: [
  //     {
  //       title: 'Create Scope',
  //       route: 'scope-add',
  //       icon: 'DatabaseIcon',
  //       Permission: '',
  //     },

  //     {
  //       title: 'Scope List',
  //       route: 'scopes-list',
  //       icon: 'HashIcon',
  //       Permission: '',
  //     },

  //     {
  //       title: 'Edit',
  //       route: 'scopes-edit',
  //       icon: 'InboxIcon',
  //       disabled: true,
  //       Permission: '',
  //     },
  //   ],
  // },

  // {
  //   title: 'Leads',
  //   icon: 'InboxIcon',
  //   Permission: 'leads_page_menu',
  //   route: 'leads-dashboard',
  // },

  // {
  //   title: 'Support Requests',
  //   icon: 'BellIcon',
  //   Permission: '',
  //   route: 'supports-dashboard',
  // },

  // {
  //   title: 'Helpdesk',
  //   icon: 'HelpCircleIcon',
  //   Permission: '',
  //   route: 'helpdesks',
  // },

  // {
  //   title: 'Portfolio',
  //   icon: 'CastIcon',
  //   Permission: 'portfolio_page_menu',
  //   route: 'portfolio-list',
  // },

  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'user-list',
    Permission: 'users_page_menu',
  },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'settings',
    Permission: 'settings_page_menu',
  },





  // {
  //   title: 'Help',
  //   icon: 'HelpCircleIcon',
  //   route: 'helpdesk',
  //   Permission: '',
  // },

  {
    title: 'Sign Out',
    icon: 'LogOutIcon',
    route: 'signout',
    Permission: '',
  },

];
